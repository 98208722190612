import React from "react"
import { Button, Layout } from 'antd'
import SiteLayout from "../components/SiteLayout";
import ServicesIntro from "../components/Sections/ServicesIntro";
import PitchVertical from "../components/Sections/PitchVertical";
import NerdMode from "../components/Sections/NerdMode";
import PitchBoxApp from "../components/Sections/PitchBoxApp";
import { StaticQuery, graphql } from "gatsby";

// @ts-ignore
import styles from './tjenester.module.scss';

export default () => (
    <StaticQuery
        query={graphql`
        {
            teaminaction: file(relativePath: {eq: "servicesvisual1.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
        }
      `
        }
        render={data => (
            <SiteLayout>
                <div className={styles.pagecontainer}>
                    <ServicesIntro />
                    <PitchVertical />
                    <PitchBoxApp />
                    <NerdMode />
                </div>
            </SiteLayout>
        )}
    />
)
