import React from 'react'
import { Row, Col, Button } from 'antd'

// @ts-ignore
import styles from './pitchboxapp.module.scss';

import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';


interface PitchBoxAppProps {
    images: any;
}

export class PitchBoxApp extends React.Component<PitchBoxAppProps, any> {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Row className={styles.smallcontainer}>
                <Col xs={24} sm={24} lg={24}>
                    <div className={styles.imagecontainer}>
                        <Img title="Oss-Brikken" fluid={this.props.images.brikken.childImageSharp.fluid} className={styles.image} alt="Oss-Brikken"/>
                    </div>
                    <div style={{ textAlign: "center" }} className={styles.header}>Oss-Brikken</div>

                    <Row type="flex" align="top">
                        <Col xs={24} sm={24} lg={24}>
                            <div className={styles.introtext}>
                                Oss-teknologien består av en komponent vi kaller Oss-Brikken, som kommuniserer direkte med plattformen vår, ved hjelp av Internet of Things kommunikasjons-teknologi (IoT).
                            </div>
                            <div className={styles.text}>
                                Du plugger Oss-Brikken direkte inn i strømmåleren din, og når du gjør det får du tilgang til dataene som de som eier strømnettet i området du bor i har. Oss-Brikken er utviklet så den oppdateres og konfigureres automatisk, og den trenger verken vedlikehold eller ettersyn.
                            </div>
                            <div className={styles.text}>
                                Den har en innebygd energireserve som gjør at den overlever strømbrudd, i tillegg til at den har temperatur-, trykk- og luftfuktighetssensor. Så brikken virkelig kan følge med på omgivelsene den er i.
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                    <div className={styles.imagecontainer}>
                        <Img title="Oss-Appen" fluid={this.props.images.appen.childImageSharp.fluid} className={styles.image} alt="Oss-Appen"/>
                    </div>
                    <div style={{ textAlign: "center" }} className={styles.header}>Oss-Appen</div>

                    <Row type="flex" align="top">
                        <Col xs={24} sm={24} lg={24}>
                            <div className={styles.introtext}>
                                Vi har også utviklet en Oss-app som oversetter og tolker informasjonen fra strømmåleren din, fra å være energipulser og kilowatt, til å bli nyttig informasjon om strømforbruket i hjemmet&nbsp;ditt.
                            </div>
                            <div className={styles.text}>
                                Appen skal i første omgang gjøre det enkelt å redusere strømforbruket ditt, ved å gi deg historiske og sanntidsdata om det, men på sikt skal den også krympe hverdagsbekymringer. Med Oss-appen skal du etterhvert kunne sjekke om ovnen er skrudd av og fryseren står på, selv når du ikke er hjemme. Alt dette får vi til ved hjelp av Oss-brikken og algoritmene som ligger&nbsp;bak.
                            </div>
                            <div className={styles.text}>
                                Enkelt sagt jobber Oss med å gjøre teknologi tilgjengelig. Liker du best det som ikke sies enkelt, foreslår vi at du sjekker ut&nbsp;#nerdemodus.
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
        {
            brikken: file(relativePath: {eq: "ossbrikkenwhite.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 1892) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
           

           appen: file(relativePath: {eq: "ossappen_services.jpg"}) {
            childImageSharp{
             fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
             }
           }
         }
       }
      `
        }
        render={data => (
            <PitchBoxApp images={data} />
        )}
    />
)

