import React from 'react'
import { Row, Col, Button } from 'antd'
import Img from "gatsby-image";

// @ts-ignore
import styles from './servicesintro.module.scss';

import { StaticQuery, graphql, Link } from 'gatsby';

export default () => (
    <StaticQuery
        query={graphql`
        {
            pitch: file(relativePath: {eq: "Oss-testbruker.png"}) {
                childImageSharp{
                 fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid_noBase64
                 }
               }
             }
           }
      `
        }
        render={data => (
            <div className={styles.pitch}>
                <div className={styles.textcontainer}>
                    <div className={styles.header}>Våre tjenester</div>

                    <div className={styles.introtext}>
                        Strømmålerne samler inn store mengder data om strømforbruket ditt. Denne informasjonen er din, men er ikke sålett tilgjengelig. Med unntak hvis du er ekstremt tech-savvy og snakker flytende elektriker. Det ville vi, Oss, gjøre noe med.
                    </div>

                    <div className={styles.imagecontainer}>
                        <Img title="Oss-Testbruker" fluid={data.pitch.childImageSharp.fluid} className={styles.image} alt="Oss-Testbruker" />
                    </div>
                </div>
            </div>
        )}
    />
)
