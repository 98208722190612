import React from 'react'
import { Row, Col, Button } from 'antd'

// @ts-ignore
import styles from './pitchvertical.module.scss';

import { StaticQuery, graphql, Link } from 'gatsby';

interface PitchText {
    id: number,
    header: string,
    intro: string,
    more: string
    color: string,
}




const PitchItem: React.SFC<PitchText> = (data) => {
    return <Col xs={24}>
        <div>
            <input type="checkbox" className={styles.readmorestate} id={data.id.toString()} />
            <ul className={styles.readmorewrap}>
                <li className={styles.subheader}>{data.header}</li>
                <li className={styles.text}>{data.intro} <span className={`${styles.readmoretarget}`}>{data.more}</span></li>
            </ul>
            <label htmlFor={data.id.toString()} className={styles.readmoretrigger}></label>
            <div className={styles.dot} style={{backgroundColor: data.color}} />
        </div>
    </Col>
}

export default class PitchVertical extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    render() {



        return (
            <Row className={styles.smallcontainer}>
                <Col xs={24} sm={24} lg={24}>
                    <div className={styles.header}>
                        Med Oss får du...
                    </div>
                    <div className={styles.subheader}>
                        Muligheten til å påvirke strømforbruket ditt
                    </div>
                    <div className={styles.text}>
                        Oss-brikken gir deg tilgang til informasjonen fra strømmåleren som du aldri før har hatt tilgang på. Oss-appen oversetter og tolker informasjonen, fra å være energipulser og kilowatt, til nyttig informasjon om strømforbruket i hjemmet ditt.
                        <br/><br/>
                    </div>
                    <div className={styles.subheader}>
                        Se strømforbruket ditt akkurat nå
                    </div>
                    <div className={styles.text}>
                        Hvor mye strøm bruker du akkurat nå? For folk flest er det helt gresk. Dette gir Oss deg muligheten til å se nå.  Kanskje klarer du ved hjelp av appen å finne strømtyvene i hjemmet ditt?
                        <br/><br/>
                    </div>
                    <div className={styles.subheader}>
                        Se ditt historiske forbruk
                    </div>
                    <div className={styles.text}>
                        Spol tilbake i tid og se ditt historiske strømforbruk. Denne tjenesten gir deg muligheten til å sammenligne forbruket ditt på minutter, timer, dager, uker, måneder og år.                    </div>
                        <br/><br/>
                    <div className={styles.subheader}>
                        Sammenlign forbruket ditt med tilsvarende hjem                    </div>
                    <div className={styles.text}>
                        Bruker jeg mye eller lite strøm lurer mange på. Få et forhold til hvordan strømforbruket ditt er sammenlignet med lignende hjem. Er du en bedre strømforbruker enn naboen?                     </div>
                        <br/><br/>
                    <div className={styles.subheader}>
                        Prediksjon av forbruk                    </div>
                    <div className={styles.text}>
                        Med våre smarte algoritmer kan vi fortelle deg hvor mye strøm du mest sannsynlig kommer til å bruke i morgen.                    </div>
                        <br/><br/>
                    <div className={styles.subheader}>
                        Se hva strømprisen er                    </div>
                    <div className={styles.text}>
                        Du kan du enhver tid følge med på hva strømmen koster, og få muligheten til å tilpasse forbruket ditt til timer med lavere strømpris. Prisindikatoren i appen viserdeg når det er billig og dyrt å bruke strøm.                    </div>
                        <br/><br/>
                    <div className={styles.subheader}>
                        Trygg 4G dekning fra Telenor                    </div>
                    <div className={styles.text}>
                        Målet er å på relativt kort sikt være på plass i alle sikringsskap i norske hjem. Eksepsjonelt god dekning er derfor helt essensielt for oss.Flaks for oss at Telenor digget vår teknologi og verdien vi ønsket å gi dere forbrukere, derfor ble vi et av deres pilot-prosjekter i utrulling av IoT teknologien LTE-M. Denne teknologien vil sikre at Oss-Brikken har god dekning der hvor sikringsskapet er plassert. Wifi tilkobling var derfor aldri et alternativ for oss.                    </div>
                        <br/><br/>
                </Col>
            </Row>
        )
    }
}
